// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/footer/Footer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/footer/Footer.tsx");
  import.meta.hot.lastModified = "1710937990000";
}
// REMIX HMR END

import { Link } from '@remix-run/react';
const navigation = {
  support: [{
    name: 'How it works',
    href: '#'
  }, {
    name: 'Hilfe',
    href: '#'
  }
  //{ name: 'FAQs', href: '#' },
  ],

  biosonah: [{
    name: 'Über Biosonah',
    href: '#'
  }, {
    name: 'Kontakt',
    href: '#'
  }, {
    name: 'Datenschutz',
    href: '#'
  }, {
    name: 'Impressum',
    href: '#'
  }]
};
export default function Footer({
  collections
}) {
  return <footer className="footer-container-shadow mt-24 border-t bg-beige-color" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:py-12 lg:px-8 mt-4">
        <div className="xl:grid xl:grid-cols-2 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-bold bg-beige-color tracking-wider uppercase">
                  Biosonah
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.biosonah.map(item => <li key={item.name}>
                      <a href={item.href} className="text-base bg-beige-color hover:bg-beige-color">
                        {item.name}
                      </a>
                    </li>)}
                </ul>
              </div>

              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-bold text-black tracking-wider uppercase">
                  Support
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.support.map(item => <li key={item.name}>
                      <a href={item.href} className="text-base bg-beige-color hover:bg-beige-color">
                        {item.name}
                      </a>
                    </li>)}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Link to="/" className="logo-link">
        <img src="/biosonah-logo.webp" width={120} height={93} alt="Biosonah logo" style={{
        marginLeft: 'auto',
        paddingBottom: '30px',
        paddingRight: '30px'
      }} />
      </Link>
    </footer>;
}
_c = Footer;
var _c;
$RefreshReg$(_c, "Footer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;