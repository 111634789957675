// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { isRouteErrorResponse, Link, Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useRouteError } from '@remix-run/react';
import styles from './styles/app.css';
import customStyles from './styles/custom.css';
import { Header } from './components/header/Header';
import { json } from '@remix-run/server-runtime';
import { getCollections } from '~/providers/collections/collections';
import { activeChannel } from '~/providers/channel/channel';
import { APP_META_DESCRIPTION, APP_META_TITLE } from '~/constants';
import { useEffect, useState } from 'react';
import { CartTray } from '~/components/cart/CartTray';
import { getActiveCustomer } from '~/providers/customer/customer';
import Footer from '~/components/footer/Footer';
import { useActiveOrder } from '~/utils/use-active-order';
import { setApiUrl } from '~/graphqlWrapper';
import { NextUIProvider } from '@nextui-org/react';
export const meta = () => {
  return [{
    title: APP_META_TITLE
  }, {
    description: APP_META_DESCRIPTION
  }];
};
export function links() {
  return [{
    rel: 'stylesheet',
    href: styles
  }, {
    rel: 'stylesheet',
    href: customStyles
  }];
}
const devMode = typeof process !== 'undefined' && process.env.NODE_ENV === 'development';

// The root data does not change once loaded.
export const shouldRevalidate = ({
  nextUrl,
  currentUrl,
  formAction
}) => {
  if (currentUrl.pathname === '/sign-in') {
    // just logged in
    return true;
  }
  if (currentUrl.pathname === '/account' && nextUrl.pathname === '/') {
    // just logged out
    return true;
  }
  if (formAction === '/checkout/payment') {
    // submitted payment for order
    return true;
  }
  return false;
};
export async function loader({
  request,
  params,
  context
}) {
  if (typeof context.VENDURE_API_URL === 'string') {
    // Set the API URL for Cloudflare Pages
    setApiUrl(context.VENDURE_API_URL);
  }
  const collections = await getCollections(request, {
    take: 20
  });
  const topLevelCollections = collections.filter(collection => collection.parent?.name === '__root_collection__');
  const activeCustomer = await getActiveCustomer({
    request
  });
  const loaderData = {
    activeCustomer,
    activeChannel: await activeChannel({
      request
    }),
    collections: topLevelCollections
  };
  return json(loaderData, {
    headers: activeCustomer._headers
  });
}
export default function App() {
  _s();
  const [open, setOpen] = useState(false);
  const loaderData = useLoaderData();
  const {
    collections
  } = loaderData;
  const {
    activeOrderFetcher,
    activeOrder,
    adjustOrderLine,
    removeItem,
    refresh
  } = useActiveOrder();
  useEffect(() => {
    // When the loader has run, this implies we should refresh the contents
    // of the activeOrder as the user may have signed in or out.
    refresh();
  }, [loaderData]);
  return <html lang="en" id="app">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="icon" href="/favicon.ico" type="image/png"></link>
        <Meta />
        <Links />
      </head>
      <body>
        <NextUIProvider>
          <Header onCartIconClick={() => setOpen(!open)} cartQuantity={activeOrder?.lines.length ?? 0} />

          <main className="">
            <Outlet context={{
            activeOrderFetcher,
            activeOrder,
            adjustOrderLine,
            removeItem
          }} />

          </main>
          <CartTray open={open} onClose={setOpen} activeOrder={activeOrder} adjustOrderLine={adjustOrderLine} removeItem={removeItem} />

          <ScrollRestoration />
          <Scripts />
          <Footer collections={collections}></Footer>

          {devMode && <LiveReload />}
        </NextUIProvider>
      </body>
    </html>;
}
_s(App, "P+5qCpIMaeLkBxFLrQZtocnqzD8=", false, function () {
  return [useLoaderData, useActiveOrder];
});
_c = App;
/**
 * You should replace this in your actual storefront to provide a better user experience.
 * You probably want to still show your footer and navigation. You will also need fallbacks
 * for your data dependant components in case your shop instance / CMS isnt responding.
 * See: https://remix.run/docs/en/main/route/error-boundary
 */
function DefaultSparseErrorPage({
  tagline,
  headline,
  description
}) {
  return <html lang="en" id="app">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="icon" href="/favicon.ico" type="image/png"></link>
        <Meta />
        <Links />
      </head>
      <body>
        <main className="flex flex-col items-center px-4 py-16 sm:py-32 text-center">
          <span className="text-sm font-semibold text-gray-500 uppercase tracking-wide">
            {tagline}
          </span>
          <h1 className="mt-2 font-bold text-gray-900 tracking-tight text-4xl sm:text-5xl">
            {headline}
          </h1>
          <p className="mt-4 text-base text-gray-500 max-w-full break-words">
            {description}
          </p>
          <div className="mt-6">
            <Link to="/" className="text-base font-medium text-primary-600 hover:text-primary-500 inline-flex gap-2">

              Go back home
            </Link>
          </div>
        </main>
        <ScrollRestoration />
        <Scripts />
        {devMode && <LiveReload />}
      </body>
    </html>;
}

/**
 * As mentioned in the jsdoc for `DefaultSparseErrorPage` you should replace this to suit your needs.
 */
_c2 = DefaultSparseErrorPage;
export function ErrorBoundary() {
  _s2();
  let tagline = 'Oopsy daisy';
  let headline = 'Unexpected error';
  let description = "We couldn't handle your request. Please try again later.";
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    tagline = `${error.status} error`;
    headline = error.statusText;
    description = error.data;
  }
  return <DefaultSparseErrorPage tagline={tagline} headline={headline} description={description} />;
}

/**
 * In Remix v2 there will only be a `ErrorBoundary`
 * As mentioned in the jsdoc for `DefaultSparseErrorPage` you should replace this to suit your needs.
 * Relevant for the future: https://remix.run/docs/en/main/route/error-boundary-v2
 */
_s2(ErrorBoundary, "oAgjgbJzsRXlB89+MoVumxMQqKM=", false, function () {
  return [useRouteError];
});
_c3 = ErrorBoundary;
export function CatchBoundary() {
  return ErrorBoundary();
}
_c4 = CatchBoundary;
var _c, _c2, _c3, _c4;
$RefreshReg$(_c, "App");
$RefreshReg$(_c2, "DefaultSparseErrorPage");
$RefreshReg$(_c3, "ErrorBoundary");
$RefreshReg$(_c4, "CatchBoundary");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;