// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/header/Header.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/header/Header.tsx");
  import.meta.hot.lastModified = "1713963521000";
}
// REMIX HMR END

import { Link } from '@remix-run/react';
import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import { SearchBar } from '~/components/header/SearchBar';
import { useRootLoader } from '~/utils/use-root-loader';
import { UserIcon } from '@heroicons/react/24/solid';
import { useScrollingUp } from '~/utils/use-scrolling-up';
import { classNames } from '~/utils/class-names';
import Calendar from '~/components/header/Calendar';
import Countdown from '~/components/header/Countdown';
import { useEffect, useRef, useState } from 'react';
export function Header({
  onCartIconClick,
  cartQuantity
}) {
  _s();
  const data = useRootLoader();
  const isSignedIn = !!data.activeCustomer.activeCustomer?.id;
  const isScrollingUp = useScrollingUp();
  const [showCalendar, setShowCalendar] = useState(false);
  const [deliveryDates, setDeliveryDates] = useState([new Date('2024-04-20 23:59:59'), new Date('2024-05-04 23:59:59'), new Date('2024-05-18 23:59:59'), new Date('2024-06-01 23:59:59'), new Date('2024-06-15 23:59:59'), new Date('2024-06-29 23:59:59')]);
  const today = new Date();
  const [nextDeliveryDate, setNextDeliveryDate] = useState(getInitialNextDeliveryDate());
  function getInitialNextDeliveryDate() {
    const today = new Date();
    const futureDates = deliveryDates.filter(date => date > today);
    if (futureDates.length > 0) {
      return futureDates.sort((a, b) => a.getTime() - b.getTime())[0];
    }
    return undefined;
  }
  const nextDeliveryDateString = nextDeliveryDate ? `${String(nextDeliveryDate.getDate()).padStart(2, '0')}.${String(nextDeliveryDate.getMonth() + 1).padStart(2, '0')}.${nextDeliveryDate.getFullYear()} ${String(nextDeliveryDate.getHours()).padStart(2, '0')}:${String(nextDeliveryDate.getMinutes()).padStart(2, '0')}` : 'Kein Bestelldatum ist geplant.';
  const calendarRef = useRef(null);
  const handleClickOutside = event => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setShowCalendar(false);
    }
  };
  useEffect(() => {
    if (showCalendar) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showCalendar]);
  useEffect(() => {
    const checkNextDeliveryDate = () => {
      if (nextDeliveryDate && nextDeliveryDate < new Date()) {
        setNextDeliveryDate(getInitialNextDeliveryDate());
      }
    };
    checkNextDeliveryDate();
    const intervalId = setInterval(checkNextDeliveryDate, 60 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [nextDeliveryDate, deliveryDates]);
  return <header className={classNames(isScrollingUp ? '' : '', 'bg-white from-zinc-700 to-gray-900 shadow-lg transform shadow-xl relative z-100')}>
      <div className="bg-green-primary-color-dark text-white shadow-inner text-center text-sm py-2 px-2 xl:px-0">
        <div className="max-w-7xl md:mx-auto mx-2  flex items-center justify-between">
          <div>
            <p className="hidden sm:block">Willkommen bei Biosonah! </p>
          </div>
          <div className="relative z-100">
            <p className="hidden sm:block">
              <a onClick={() => setShowCalendar(!showCalendar)} className="underline font-bold relative inline-block">
                Nächste Bestellung am: {nextDeliveryDateString}
              </a>
            </p>
            {showCalendar && <div ref={calendarRef} className="relative bg-white shadow-md overflow-visible">
                <Calendar highlightedDates={deliveryDates} />
              </div>}
          </div>

          <div>
            <Countdown nextDeliveryDate={nextDeliveryDate} />
          </div>
          <div>
            <p>
              <a
            //href="https://github.com/vendure-ecommerce/storefront-remix-starter" -->Later on: Insert Link
            target="_blank">
                Kontakt
              </a>
            </p>
          </div>
          <div>
            <p>
              <a
            //href="https://github.com/vendure-ecommerce/storefront-remix-starter" -->Later on: Insert Link
            target="_blank">
                How it works
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white max-w-7xl mx-auto p-4 flex items-center space-x-4 ">
        <h1 className="text-black ">
          <Link to="/">
            <img src="/biosonah-logo.webp" width={80} height={62} alt="Biosonah logo" />
          </Link>
        </h1>

        <div className="flex-1 md:pr-64 md:pl-64 w-1/12">
          <SearchBar></SearchBar>
        </div>
        <div>
          <Link to={isSignedIn ? '/account' : '/sign-in'} className="flex space-x-1 bg-white bg-opacity-0 rounded text-black p-1">
            <UserIcon className="w-4 h-6 bg-white"></UserIcon>
            <span>{isSignedIn ? 'Mein Konto' : 'Anmelden'}</span>
          </Link>
        </div>
        <div className="">
          <button className="relative w-9 h-9 bg-white bg-opacity-0 rounded text-black p-1" onClick={onCartIconClick} aria-label="Open cart tray">
            <ShoppingBagIcon className="w-6 h-6"></ShoppingBagIcon>
            {cartQuantity > 0 && <div className="absolute rounded-full -top-1 -right-1 bg-green-primary-color-light w-6 h-6 flex items-center justify-center text-white font-bold text-xs">
                {cartQuantity}
              </div>}
          </button>
        </div>
      </div>

      <div className="bg-white max-w-6xl mx-auto p-4 flex items-center space-x-4 justify-center">
        <div className="flex space-x-4 hidden sm:block">
          {data.collections.map(collection => <Link className="text-sm font-bold md:text-base text-black uppercase hover:text-green-pri" to={'/collections/' + collection.slug} prefetch="intent" key={collection.id}>
              {collection.name}
            </Link>)}
        </div>
      </div>
    </header>;
}
_s(Header, "+fNGQHk7I6NSQhh3ZGfp2wdrTGA=", false, function () {
  return [useRootLoader, useScrollingUp];
});
_c = Header;
var _c;
$RefreshReg$(_c, "Header");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;